function getContent(str) {

    r = str.split('<')
    let b
    for (let i = 0; i < r.length; i++) {
        if (r[i][0] === '/') {
            b = i - 1
            break
        }
    }
    return r[b].split('>')[1]
}

module.exports = getContent